<template>
  <div class="totop">
    <div class="show" :class="isShow ? 'isShow' : ''">
      <div @click="isShow = !isShow"><img src="@/assets/img/common/weibo01.png" /></div>
      <div @click="isShow = !isShow">
        <img src="@/assets/img/common/us.png" />
        <span>立</span>
        <span>即</span>
        <span>咨</span>
        <span>询</span>
      </div>
      <div @click="backTop">
        <img src="@/assets/img/common/top.png" />
      </div>
    </div>
    <div class="telephone" :class="isShow ? 'active' : ''">
      <div class="telephone_name">
        <div class="name">立即咨询 <span class="iconfont icon-shanchu" @click="isShow = !isShow"></span></div>
        <div class="phone">
          <div class="text">商务洽谈</div>
          <div class="imgs">
            <img src="@/assets/img/common/telephone.png" /><span>{{ code[0] }}</span>
            <img src="@/assets/img/common/qq.png" class="img" @click="qq(1413978325)" />
          </div>
          <div class="imgs">
            <img src="@/assets/img/common/telephone.png" /><span>{{ code[1] }}</span>
            <img src="@/assets/img/common/qq.png" class="img" @click="qq(1413978325)" />
          </div>

          <div class="text">技术支持</div>
          <div class="imgs">
            <img src="@/assets/img/common/telephone.png" /><span>{{ code[2] }}</span>
            <img src="@/assets/img/common/qq.png" class="img" @click="qq(1143735430)" />
          </div>
        </div>
      </div>
      <div class="qq">
        <span>微博</span><img src="@/assets/img/common/weibo.png" @click="weibo" /><!--  <img src="@/assets/img/common/qq.png" @click="qq" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true
    }
  },
  methods: {
    //返回顶部
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 60
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    qq(qq) {
      window.open(`tencent://Message/?Uin=${qq}&amp;websiteName=local.edu.com:8888=&amp;Menu=yes`, '_blank')
    },
    weibo() {
      window.open('https://weibo.com/ushareyun', '_blank')
    }
  },
  computed: {
    code() {
      return this.$store.state.codeList
    }
  },
  created() {
    this.$store.dispatch('getCodeList')
  }
}
</script>

<style lang="scss" scoped>
.isShow {
  right: -7.894736rem !important;
}
.active {
  right: 0.263157rem !important;
}
.totop {
  position: fixed;
  z-index: 999;
  bottom: 1.315789rem;
  right: 0;
  height: 18.421052rem;
  .show {
    transition: all 0.5s ease;
    width: 2.2rem;
    position: absolute;
    right: 0.5rem;
    bottom: 2.947368rem;
    div {
      &:first-child {
        width: 2.2rem;
        height: 2.2rem;
        background-color: #59b839;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
        border-radius: 0.4rem;
        img {
          max-width: 75%;
        }
      }
      &:nth-child(2) {
        width: 2.2rem;
        height: 7.5rem;
        background-color: #59b839;
        border-radius: 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        img {
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
        span {
          font-size: 0.947368rem;
          color: white;
        }
      }
      &:last-child {
        position: absolute;
        z-index: 9999;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        background-color: #59b839;
        overflow: hidden;
        img {
          display: block;
          margin: 0 auto;
          margin-top: 20%;
          max-width: 60%;
        }
      }
    }
  }

  .telephone {
    transition: all 0.5s ease;
    position: absolute;
    right: -13.157894rem;
    top: 0.052631rem;
    width: 0px;
    // display: none;
    width: 10.789473rem;
    height: 15.789473rem;
    .telephone_name {
      height: 12.789473rem;
      width: 10.789473rem;
      // background-image: url("~@/assets/img/common/isshow.png");
      // background-size: 100% 100%;
      background-color: #ffffff;
      border-radius: 0.263157rem 0.263157rem 0 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      .name {
        height: 2.105263rem;
        padding-left: 1.315789rem;
        line-height: 2.236842rem;
        background-color: #59b839;
        border-radius: 0.263157rem 0.263157rem 0 0;
        font-size: 0.789473rem;
        color: #ffffff;
        span {
          font-size: 1.052631rem;
          float: right;
          margin-right: 1.315789rem;
        }
      }
      .phone {
        flex: 1;
        border: 1px solid #eeeeee;
        border-top: none;
      }
      .text {
        font-size: 0.631578rem;
        color: #333;
        padding-left: 0.789473rem;
        margin: 0.526315rem 0 0.394736rem;
      }
      .imgs {
        padding-left: 0.789473rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.526315rem;
        font-size: 0.631578rem;
        color: #333;
        cursor: pointer;
        img {
          width: 1.447368rem;
          height: 1.710526rem;
          margin-right: 0.526315rem;
        }
        .img {
          width: 1rem;
          height: 1rem;
          margin-left: 0.3rem;
        }
      }
    }
    .qq {
      border-radius: 0.263157rem;
      // width: 10.526315rem;
      margin: 0.789473rem auto 0;
      height: 2.631578rem;
      background-color: #59b839;
      // box-shadow: 0px 2px 5px 0px #c8c8c8 ;
      font-size: 0.789473rem;
      color: white;
      line-height: 2.631578rem;
      padding: 0 1.315789rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 80%;
        // &:last-child {
        //   height: 50%;
        // }
        cursor: pointer;
      }
    }
  }
}
</style>
