<template>
  <div id="pc">
    <div class="advertising">
      <div class="adver_banner">
        <div class="close " @click="close" :class="isAdver ? '' : 'dis'">
          <i class="icon-shanchu iconfont font"></i>
        </div>
        <div class="advertising_img" :class="isAdver ? 'show' : 'hidden'"
          :style="{ backgroundImage: 'url(' + generaCompletePath + ')' }"></div>
      </div>
    </div>
    <Tabbar :isShow="isShow"></Tabbar>
    <ToTop v-if="show"></ToTop>
    <router-view />
  </div>
</template>

<script>
import request, { BaseURL } from '@/utils/request.js'
let time
window.onresize = throttle

//防抖函数避免多次去刷新页面
function throttle() {
  clearTimeout(time)
  time = setTimeout(() => {
    let isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (isMobile) {
      location.reload()
    }
  }, 2000)
}

import Tabbar from '@/components/pc/tabbar/tabbar.vue'
import ToTop from '@/components/pc/commom/totop/index.vue'
export default {
  data() {
    return {
      isShow: false,
      imgUrl: '',
      isHttp: false,
      show: true,
      // 'https://ss3.baidu.com/-fo3dSag_xI4khGko9WTAnF6hhy/zhidao/wh%3D450%2C600/sign=c075acfcf91f4134e0620d7a102fb9fc/96dda144ad345982d4dcd48d08f431adcbef845e.jpg'
    }
  },
  components: {
    Tabbar,
    ToTop
  },
  methods: {
    handleScroll() {
      let length = this.isAdver ? 120 + 400 : 120
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if (scrollTop >= length) {
        console.log(length, scrollTop)
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    close() {
      this.$store.commit('setIsAdver', false)
    },
    async getUrl() {
      let result = await request.request({
        url: 'api/get_code',
        method: 'POST',
        data: { code: 'HOME_ADV' }
      })
      let { returnCode, data } = result
      console.log(result, 555555555555)
      if (returnCode == 1) {
        if (data == null || data == '') {
          return
        } else {
          this.imgUrl = data
          if (data.search('http') != -1) {
            this.isHttp = true
          }
          setTimeout(() => {
            this.$store.commit('setIsAdver', true)
          }, 1000)
          setTimeout(() => {
            this.$store.commit('setIsAdver', false)
          }, 6000)
        }
      } else {
        this.$message.error('出错了请刷新')
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    // 获取当前页面的路由路径
    const currentRoute = this.$route.path;
    if (currentRoute.includes('authOpenId')) {
      this.show = false; // 如果包含authOpenId，则将show值设为false
      this.$forceUpdate();
    }
  },
  computed: {
    isAdver() {
      return this.$store.state.isAdver
    },
    generaCompletePath() {
      if (this.isHttp && this.imgUrl) {
        return this.imgUrl
      } else if (!this.isHttp && this.imgUrl) {
        return `${BaseURL}static${this.imgUrl}`
      }
    }
  },
  created() {
    // setTimeout(() => {
    //   this.$store.commit('setIsAdver', true)
    // }, 1000)
    this.getUrl()
  }
}
</script>

<style lang="scss">
@import url('~@/style/pc.css');

.adver_banner {
  position: relative;

  .close {
    position: absolute;
    top: 10px;
    right: 20px;

    .font {
      display: inline-block;
      font-size: 40px;
    }
  }

  .advertising_img {
    width: 100%;
    transition: all 2s;
    background-size: auto 400px;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    background-color: #930a10;
  }
}

.show {
  height: 400px;
}

.hidden {
  height: 0px;
}

.dis {
  display: none;
}
</style>
