<template>
  <div class="tabbar_parent">
    <div class="tabbar">
      <!-- <div class="advertising">
        <el-collapse-transition>
          <div v-show="true">
            <div class="advertising_img"></div>
          </div>
        </el-collapse-transition>
      </div> -->
      <div class="context">
        <div class="left">
          <img src="@/assets/logo.png" @click="imgClick" />
        </div>
        <div class="right">
          <template v-for="(item, index) in tabbarList">
            <template v-if="item.children ? false : true">
              <div class="item" :class="{ active: index == currindex }" :key="item.id">
                <div @click="tabbarChange(item, index)">{{ item.name }}</div>
              </div>
            </template>
            <template v-else>
              <el-dropdown class="item" placement="top" :class="{ active: index == currindex }" :key="item.id">
                <div>
                  {{ item.name }}
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item1 in item.children" :key="item1.name" @click.native="tabbarChange(item1, index)">{{
                    item1.name
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </template>
          <div class="btn" @click="trial" v-if="show">免费试用</div>
        </div>
      </div>
    </div>
    <el-collapse-transition class="tabbar">
      <div class="two_tabbar" v-show="isShow">
        <div class="context">
          <div class="left">
            <img src="@/assets/logo_2.png" @click="imgClick" />
          </div>
          <div class="right">
            <template v-for="(item, index) in tabbarList">
              <template v-if="item.children ? false : true">
                <div class="item" :class="{ active: index == currindex }" :key="item.id">
                  <div @click="tabbarChange(item, index)">{{ item.name }}</div>
                </div>
              </template>
              <template v-else>
                <el-dropdown class="item" placement="top" :class="{ active: index == currindex }" :key="item.id">
                  <div>
                    {{ item.name }}
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item1 in item.children" :key="item1.name" @click.native="tabbarChange(item1, index)">{{
                      item1.name
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </template>
            <div class="btn" @click="trial">免费试用</div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import active from '@/mixnis/active.js'
export default {
  computed: {
    currindex() {
      return this.$store.state.currindex
    },
    isAdver() {
      return this.$store.state.isAdver
    }
  },
  mixins: [active],
  data() {
    return {
      table: false,
      dialog: false,
      loading: false,
      show: true,
      // currindex: 0,
      tabbarList: [
        {
          name: '特征',
          path: '/home'
        },
        {
          name: '产品',
          path: '',
          children: [
            {
              name: '产品',
              path: '/product'
            },
            {
              name: '产品体系',
              path: '/system'
            },
            {
              name: '下载',
              path: '/download'
            }
          ]
        },
        {
          name: '方案',
          path: '',
          children: [
            {
              name: '企业准入方案',
              path: '/enterprise'
            } /**, {
								name: "酒店实名上网方案",
								path: "/hotel"
							},
							{
								name: "政务免费实名入网",
								path: "/interaction"
							}, {
								name: "连锁机构统一准入方案",
								path: "/interaction"
							} */
          ]
        }
      ],
      activeIndex: 'home'
      // isShow:true
    }
  },
  props: {
    isShow: {
      default: false
    }
  },
  methods: {
    tabbarChange(item, index) {
      // console.log(item,index);
      // this.$store.commit('setCurrindex',index)
      // this.currindex = index
      this.$router.push(item.path)
    },
    handleSelect() {
      // console.log(111);
    },
    trial() {
      // this.$store.commit('setCurrindex',100)
      // this.currindex = 100
      this.$router.push('/trial')
    },
    imgClick() {
      if (this.$route.path != '/home') {
        this.$router.push('/home')
      }
    }
  },
  mounted() {
    // 获取当前页面的路由路径
    const currentRoute = this.$route.path;
    if (currentRoute.includes('authOpenId')) {
      this.show = false; // 如果包含authOpenId，则将show值设为false
      this.$forceUpdate();
    }
  },
}
</script>

<style lang="scss" scoped>
// @media (max-width: 1023xpx){
// 	@import "@/style/tabbar/m.index.scss"
// } /*>=1024的设备*/
// @media (min-width: 1024px){
@import '@/style/pc/tabbar/index.scss';
.tabbar_parent {
  position: relative;
}
// .isTop {
//   top: 250px;
// }
</style>
